.shoppin__btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border: 1px solid;
  margin-right: 10px;
  background-color: #29202059;
}

.shoppin__btn:hover {
  background-color: #a18e8e59;
  transition: 250ms ease-in-out;
}

.searchBox__wrapper {
  position: relative;
}

.search__suggestion__container {
  position: absolute;
  background-color: rgb(15 11 11 / 97%);
  width: 100%;
  /* height: 186px; */
  top: 44px;
  border-radius: 1px 1px 8px 8px;
  box-shadow: 4px 2px 14px #151414;
}

.search__suggestion__list {
}

.search__suggestion__item {
  list-style: none;
  padding: 6px;
  border-bottom: 1px solid dimgrey;
  cursor: pointer;
}

.search__suggestion__item:last-child {
  border: none;
  border-radius: 1px 1px 8px 8px;
}

.search__suggestion__item:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.mobileSearch .search__suggestion__container {
  box-shadow: 4px 2px 14px #b8a7a7;
  background-color: white;
  z-index: 2;
}

.mobileSearch .search__suggestion__item {
  border-bottom: 1px solid #e6dcdc;
  color: black;
}

.mobileSearch .search__suggestion__item:hover {
  background-color: rgba(255, 215, 255, 1.25);
}

.suggestion__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
}
